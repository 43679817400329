import React, { Fragment, useEffect, useRef, useState } from 'react'
import axios from 'axios'

import { useNavigate, useParams } from 'react-router-dom'
import { Button, FormHandler, Grid } from 'components'

import { defaultBillingInfo, billingInfoFields } from './billingInfoFields'

const CompanyBillingInfo = () => {
    /*------------------------------------STATE--------------------------------*/
    const [billingInfo, setBillingInfo] = useState(defaultBillingInfo)
    const [stateOptions, setStateOptions] = useState({})
    const [taxRegimeOptions, setTaxRegimeOptions] = useState({})
    const [cfdiUseOptions, setCfdiUseOptions] = useState({})
    const [cities, setCities] = useState([])
    const [touched, setTouched] = useState(false)

    /*------------------------------------HOOKS--------------------------------*/
    const navigate = useNavigate()
    const { companyId } = useParams()
    const formRef = useRef()

    /*------------------------------------FUNCTIONS--------------------------------*/
    const handleChangeBillingInfo = (formValue) => {
        setTouched(true)
        setBillingInfo(formValue)
        if (Object.keys(formValue())[0] === 'state_id') {
            setBillingInfo(billingInfo => ({ ...billingInfo, city_id: '' }))
        }
    }

    const getBillingInfo = async () => {
        try {
            const response = await axios.get(`/admin/companies/${companyId}/billing-data/details`, { appSpinner: true })
            if (response.data) {
                setBillingInfo(response.data.response)
            }
        } catch (error) {
            console.error('GET_BILLING_INFO_ERROR', error)
        }
    }

    const getStateOptions = async () => {
        try {
            const response = await axios.get('/common/geographical/states', { appSpinner: true })
            if (response.data) {
                setStateOptions(global.arrayToObjectOptions(response.data.response, 'id', 'name'))
            }
        } catch (error) {
            console.error('GET_STATE_OPTIONS_ERROR', error)
        }
    }

    const getCityOptions = async () => {
        try {
            const response = await axios.get('/common/geographical/cities', { appSpinner: true })
            if (response.data) {
                setCities(response.data.response)
            }
        } catch (error) {
            console.error('GET_CITY_OPTIONS_ERROR', error)
        }
    }

    const getTaxRegimeOptions = async () => {
        try {
            const response = await axios.get('/common/invoices/tax-regimes', { appSpinner: true })
            if (response.data) {
                setTaxRegimeOptions(global.arrayToObjectOptions(response.data.response, 'id', 'name'))
            }
        } catch (error) {
            console.error('GET_TAX_REGIME_ERROR', error)
        }
    }

    const getCfdiUseOptions = async () => {
        try {
            const response = await axios.get('/common/invoices/cfdi-uses', { appSpinner: true })
            if (response.data) {
                setCfdiUseOptions(global.arrayToObjectOptions(response.data.response, 'id', 'name'))
            }
        } catch (error) {
            console.error('GET_CFDI_USE_ERROR', error)
        }
    }

    const saveBillinginfo = async () => {
        if (!formRef.current.validate()) return
        try {
            const response = await axios.post(`/admin/companies/${companyId}/billing-data/save`, { ...billingInfo }, { appSpinner: true })
            if (response.data.success) {
                setTouched(false)
            }
        } catch (error) {
            console.error('SAVE_BILLING_INFO_ERROR', error)
        }
    }

    /*------------------------------------EFFECT--------------------------------*/
    useEffect(() => {
        getBillingInfo()
        getStateOptions()
        getCityOptions()
        getTaxRegimeOptions()
        getCfdiUseOptions()
        // run effect once
        // eslint-disable-next-line
    }, [])

    /*------------------------------------EFFECT--------------------------------*/
    const cityOptions = {}
    cities.forEach(city => {
        if (Number(city.stateId) === Number(billingInfo.state_id)) {
            cityOptions[city.id] = city.name
        }
    })

    return (
        <Fragment>
            <Button alignSelf='start' onClick={() => navigate(-1)}>Regresar</Button>
            <Grid className='company_billing_info' gap='0.5rem' padding='1rem' >
                <Grid w100 maxWidth='40rem' gap='1rem' selfX='center'>
                    <FormHandler
                        ref={formRef}
                        fields={billingInfoFields(stateOptions, cityOptions, taxRegimeOptions, cfdiUseOptions)}
                        formData={billingInfo}
                        setFormData={handleChangeBillingInfo}
                    />
                    {touched && <Button justifySelf='center' onClick={saveBillinginfo}>Guardar</Button>}
                </Grid>
            </Grid>
        </Fragment>
    )
}

export default CompanyBillingInfo
